import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import { httpClient } from "appUtility/Api";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  Tab,
  Tabs,
  Fade,
  Card,
} from "@material-ui/core";
// import { Autocomplete, Stack } from '@material-ui/material'
import TopInfoBox from "./topInfoBox";
import moment from "moment";
import { Close, ContactPhoneOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ThankYouReference from "../../../components/ThankYou";

const qs = require("qs");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SelectPostCode(props) {
  const {
    suburbList,
    setSuburbList,
    franchiseList,
    setFranchiseList,
    suburbCurrent,
    setSuburbCurrent,
    currentFranchiseList,
    setCurrentFranchiseList,
    inputValue,
    setInputValue,
    suburbStackList,
    setSuburbStackList,
    radios,
    setPreviousForwardedScenario,
    currentSelectedFranchise,
    setCurrentSelectedFranchise,
    franchiseInput,
    setFranchiseInput,
    franchiseSuburbsList,
    setFranchiseSuburbsList,
    franchiseSuburb,
    setFranchiseSuburb,
  } = props;

  const classes = useStyles();
  const [message, setMessage] = useState(true);
  const [postCodeError, setPostCodeError] = useState("");
  const [currSuburb, setCurrSuburb] = useState("");
  const [currState, setCurrState] = useState("");
  const [count, setCount] = useState("");
  const [count2, setCount2] = useState("");

  const [currentText, setCurrentText] = useState("");
  const [currFranchise, setCurrFranchise] = useState("");
  const [totalFranchiseData, setTotalFranchiseData] = useState([]);
  const [currCompanyType, setCurrCompanyType] = useState("");

  const [value, setValue] = useState(0);
  const [showOrgBox, setShowOrgBox] = useState(false);
  const [showEndCall, setShowEndCall] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowOrgBox(false);
  };

  let suspendedFranshise = {};
  let franchiseName = "";
  let commpanyType = "";
  let newSuburbName = "";

  let loginData = JSON.parse(localStorage.getItem("login"));

  if (suburbList && suburbList.length > 0 && suburbList[0] != undefined) {
    newSuburbName = props.suburb || suburbList[0].suburbName;
  } else if (franchiseSuburbsList && franchiseSuburbsList.length > 0) {
    newSuburbName = franchiseSuburbsList[0].suburbName;
  }

  useEffect(() => {
    if (value === 0) {
      let franchiseLists = [];
      setShowEndCall(false);
      setCount("");
      suburbList &&
        suburbList.map((sub) => {
          if ((newSuburbName || props.suburb) === sub.suburbName) {
            setCount(parseInt(sub.count));
            if (sub.memberNames && sub.memberNames !== null) {
              franchiseLists = franchiseLists.concat(sub.memberNames);
            }
          }
        });

      const sortedFranchiseList = [...franchiseLists].sort((a, b) => {
        if (a.businessType === props.currentOrganization) return -1;
        if (b.businessType === props.currentOrganization) return 1;
        return 0;
      });

      franchiseLists = sortedFranchiseList;

      setFranchiseList(sortedFranchiseList);
      if (franchiseLists.length > 0 && franchiseLists != undefined) {
        franchiseName =
          franchiseLists[0] != undefined && franchiseLists[0].companyID;
        commpanyType =
          franchiseLists[0] != undefined && franchiseLists[0].businessType;
        // franchiseLists[0].map(fra => {
        //   franchiseName = fra[0].companyID
        // })
      }

      if (
        commpanyType.length > 0 &&
        commpanyType !== props.currentOrganization &&
        franchiseLists.length === 1
      ) {
        setShowOrgBox(true);
      }
    }
  }, [props.suburb, newSuburbName]);

  useEffect(() => {
    setPreviousForwardedScenario(radios);
  }, []);

  if (
    franchiseList &&
    franchiseList.length > 0 &&
    franchiseList[0] != undefined
  ) {
    franchiseName = franchiseList[0] != undefined && franchiseList[0].companyID;
    commpanyType =
      franchiseList[0] != undefined && franchiseList[0].businessType;
  }

  franchiseList &&
    franchiseList.length > 0 &&
    franchiseList &&
    franchiseList.map((fList) => {
      // if (fList.companyID == franchiseName) {
      if (
        fList.companyID == (props.franchise ? props.franchise : franchiseName)
      ) {
        suspendedFranshise = fList;
        props.setSuspendedFranchisee(fList);
        props.setOperatorName(fList.name);
      }
    });

  let suspendedText = `Unfortunately ${suspendedFranshise.businessType} - ${
    suspendedFranshise.name
  } is not accepting any new customer from ${moment(
    suspendedFranshise.leadSuspendStartDate
  ).format("dddd, Do MMMM YYYY")} to ${moment(
    suspendedFranshise.leadSuspendEndDate
  ).format("dddd, Do MMMM YYYY")} . Sorry for the inconveinence.`;

  const handlePostCodePress = (e) => {
    if (e.key === "Enter") {
      var postQuery = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        postCode: props.postCode,
      };
      httpClient
        .post(
          "getSuburbs_Franchise.php",
          qs.stringify({
            action: "get_suburbs",
            source: postQuery,
          })
        )
        .then((res) => {
          if (res.data.error) {
            setPostCodeError(
              "Invalid Post Code. Please Re-Enter your Post Code"
            );
            setSuburbList([]);
            setCurrSuburb(null);
            setCurrState(null);
          } else {
            setPostCodeError("");
            let datas = res.data;
            let suburbLists = [];

            datas &&
              datas.map((data) => {
                return suburbLists.push(data);
              });

            setSuburbList(suburbLists);
            setMessage(true);
            setCurrSuburb(suburbLists[0].suburbName);
            setCurrState(suburbLists[0].stateID);
            props.setState(suburbLists[0].stateShortName);
            props.setStateID(suburbLists[0].stateID);
            props.setSuburb(suburbLists[0].suburbName);
          }
        });
    }
  };

  const handleSuburbPress = (e) => {
    if (e.key === "Enter") {
      let params = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        name: inputValue,
      };
      httpClient
        .post(
          "getSuburbsByName.php",
          qs.stringify({
            action: "get_suburbs",
            source: params,
          })
        )
        .then(({ data }) => {
          if (data) {
            setSuburbStackList(data);
          }
        });
    }
  };

  const handleSuburbSelect = (e, item) => {
    // if (e.target.value !== 0 && e.target.value !== undefined) {
    //   let params = {
    //     userID: loginData && loginData.uid,
    //     userCode: loginData && loginData.aCode,
    //     appID: 1,
    //     name: e.target.value,
    //   };
    //   httpClient
    //     .post(
    //       "getSuburbsByName.php",
    //       qs.stringify({
    //         action: "get_suburbs",
    //         source: params,
    //       })
    //     )
    //     .then(({ data }) => {
    //       if (data) {
    //         setSuburbStackList(data);
    //       }
    //     });
    // } else {
    if (item != null) {
      let paramsSuburb = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        suburbID: item.suburbID,
      };
      httpClient
        .post(
          "getPostCode_Franchise.php",
          qs.stringify({
            action: "get_postCode",
            source: paramsSuburb,
          })
        )
        .then(({ data }) => {
          if (data) {
            setSuburbCurrent(data);
            setCount2(eval(data[0].count));
            props.setSuburb(data[0].suburbName);
            props.setState(data[0].stateShortName);
            props.setStateID(data[0].stateID);
            props.setPostCode(data[0].postCode);
            let franchiseLists = [];
            if (data[0].memberNames && data[0].memberNames !== null) {
              // return franchiseLists.push(data[0].memberNames);
              franchiseLists.push(data[0].memberNames);
            }
            setFranchiseList(franchiseLists);
            if (franchiseLists.length > 0 && franchiseLists != undefined) {
              franchiseName =
                franchiseLists[0] != undefined &&
                franchiseLists[0][0].companyID;
              commpanyType =
                franchiseLists[0] != undefined &&
                franchiseLists[0][0].businessType;
            }
            if (
              commpanyType.length > 0 &&
              commpanyType !== props.currentOrganization
            ) {
              setShowOrgBox(true);
            }
          }
        });
      //   }
    }
  };

  const handleCloseOrgBox = () => {
    setShowOrgBox(false);
    props.handleContinue();
  };

  const closeShowEndCall = () => {
    setShowEndCall(true);
    setShowOrgBox(false);
  };

  const handleFranchisePress = (e) => {
    if (e.key === "Enter") {
      let params = {
        userID: loginData && loginData.uid,
        userCode: loginData && loginData.aCode,
        appID: 1,
        franchiseeName: franchiseInput,
      };

      httpClient
        .post(
          "getSuburbs_Postcode.php",
          qs.stringify({
            action: "get_suburbs",
            source: params,
          })
        )
        .then(({ data }) => {
          if (data) {
            setTotalFranchiseData(data);
            let currFralist = [];
            !data.error &&
              data.map((item) => {
                // item.memberNames.map(mem => {
                //   currFralist.push(mem)
                // })
                currFralist.push(item);
              });
            setCurrentFranchiseList(currFralist);
          }
        });
    }
  };

  const handleFranchiseChange = (e, item) => {
    if (item != null) {
      setCurrentSelectedFranchise(item);
      props.setSuspendedFranchisee(item);
      setFranchiseSuburbsList(item.suburbName);
      // setFranchiseSuburb(item.companyID);
      setCurrCompanyType(item.businessType);
      commpanyType = item.businessType;
      setCount2(1);
      if (item.suburbName !== null) {
        props.setState(item.suburbName[0].stateShortName);
        props.setStateID(item.suburbName[0].stateID);
        props.setPostCode(item.suburbName[0].postCode);
      }
    }
  };

  if (currCompanyType != "") {
    commpanyType = currCompanyType;
  }

  const handleFranchiseSuburb = (e) => {
    franchiseSuburbsList !== null &&
      franchiseSuburbsList.map((data) => {
        if (data.suburbName === e.target.value) {
          setFranchiseSuburb(data.suburbName);
          props.setState(data.stateShortName);
          props.setStateID(data.stateID);
          props.setPostCode(data.postCode);
        }
      });
  };

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleFranchiseInputChange = (event, newValue) => {
    setFranchiseInput(newValue);
  };

  props.setCompanyID(props.franchise || franchiseName);

  return (
    <ResponsiveContainer>
      <div className="row">
        {props.showResetButton ? (
          <div className="col-md-12">
            <TopInfoBox text="Thank you for your call" />
          </div>
        ) : (
          <div className="col-md-12">
            {value === 3 ? (
              <TopInfoBox
                text="Who would you like to leave a message for ?"
                subText="Note: Only used if the caller asks for the Operator by name"
              />
            ) : (
              (props.serviceRequired ||
                props.messageOperator ||
                props.declined) &&
              !props.cancelBooking &&
              !props.franchiseEnquiry &&
              !props.advertisingEnquiry &&
              !props.complaint &&
              ((count > 0 &&
                (count !== 1 || commpanyType === props.currentOrganization)) ||
              (count2 > 0 &&
                (count2 !== 1 ||
                  commpanyType === props.currentOrganization)) ? (
                <TopInfoBox text="We have an Operator in your area, may I take your details and have your call returned as soon as possible?" />
              ) : showOrgBox &&
                count === 1 &&
                commpanyType !== props.currentOrganization ? (
                <TopInfoBox
                  text={`Unfortunately we don’t have ${props.currentOrganization}, but have ${commpanyType}, would you like to proceed with our ${commpanyType} Franchisee?`}
                />
              ) : count === 0 ? (
                <TopInfoBox text="Unfortunately there is no one currently servicing your area. Sorry we are unable to assist you at this time. Thank you for calling." />
              ) : value == 2 ? (
                <TopInfoBox
                  text="So I can check if we have an Operator that services your area, can you search by franchise/operator name?"
                  // subText={"And your suburb please ?"}
                />
              ) : value == 1 ? (
                <TopInfoBox
                  text="So I can check if we have an Operator that services your area, can you search by suburb name?"
                  // subText={"And your suburb please ?"}
                />
              ) : (
                <TopInfoBox
                  text="So I can check if we have an Operator that services your area, may I please have your postcode?"
                  subText={"And your suburb please ?"}
                />
              ))
            )}
            {props.cancelBooking &&
              !props.franchiseEnquiry &&
              !props.advertisingEnquiry &&
              !props.complaint &&
              ((count && count >= 0) || (count2 && count2 >= 0) ? (
                <TopInfoBox text="I will pass on your message straight away, I will just need to take down a few details first." />
              ) : value == 2 ? (
                <TopInfoBox
                  text="Can you search by franchise/operator name?"
                  // subText={"And your suburb please ?"}
                />
              ) : value == 1 ? (
                <TopInfoBox
                  text="Can you search by suburb name?"
                  // subText={"And your suburb please ?"}
                />
              ) : (
                <TopInfoBox text="May I have the postcode and the suburb where the booking is arranged for ?" />
              ))}

            {(props.franchiseEnquiry ||
              props.advertisingEnquiry ||
              props.complaint) &&
              !props.cancelBooking &&
              ((count && count >= 0) || (count2 && count2 >= 0) ? (
                <TopInfoBox text="I will pass on your message straight away, I will just need to take down a few details first." />
              ) : value == 2 ? (
                <TopInfoBox
                  text="Can you search by franchise/operator name?"
                  // subText={"And your suburb please ?"}
                />
              ) : value == 1 ? (
                <TopInfoBox
                  text="Can you search by suburb name?"
                  // subText={"And your suburb please ?"}
                />
              ) : (
                <TopInfoBox text=" May I ask which postcode and suburb you are calling from ?" />
              ))}
          </div>
        )}

        <div className="col-md-8">
          {props.showResetButton ? (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          ) : (
            <div className={classes.selectBox}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab label="Post Code" {...a11yProps(0)} />
                    <Tab label="Suburb" {...a11yProps(1)} />
                    <Tab label="Franchise" {...a11yProps(2)} />

                    {/* {props.messageOperator && (
                    <Tab label="Operator" {...a11yProps(3)} />
                  )} */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="mt-4">
                    <InputLabel>Post Code</InputLabel>
                    <TextField
                      variant="outlined"
                      name="postCode"
                      value={props.postCode}
                      onChange={(e) => props.handlePostCode(e)}
                      onKeyPress={(e) => handlePostCodePress(e)}
                      helperText={
                        postCodeError
                          ? postCodeError
                          : props.postCode &&
                            "Please Press Enter after you have inserted your Post Code"
                      }
                      margin="normal"
                      fullWidth
                    />
                  </div>

                  {suburbList && suburbList.length > 0 && (
                    <div>
                      <div className="mt-3">
                        <InputLabel>Suburb</InputLabel>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", marginTop: "10px" }}
                        >
                          <Select
                            name="suburb"
                            // onChange={(e) => handleSuburb(e)}
                            value={newSuburbName || props.suburb}
                            onChange={props.handleSuburb}
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {suburbList &&
                              suburbList.map((sub) =>
                                sub.count == "0" ? (
                                  <MenuItem
                                    style={{ color: "red" }}
                                    key={sub.suburbID}
                                    value={sub.suburbName}
                                  >
                                    <span style={{ color: "red" }}>
                                      {sub.suburbName +
                                        "," +
                                        " " +
                                        sub.stateShortName +
                                        " " +
                                        "(" +
                                        sub.count +
                                        ")"}
                                    </span>
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    style={{ color: "green" }}
                                    key={sub.suburbID}
                                    value={sub.suburbName}
                                  >
                                    <span style={{ color: "green" }}>
                                      {sub.suburbName
                                        ? sub.suburbName +
                                          "," +
                                          " " +
                                          sub.stateShortName +
                                          " " +
                                          "(" +
                                          sub.count +
                                          ")"
                                        : sub}
                                    </span>
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="mt-3">
                        <InputLabel>Franchise</InputLabel>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%", marginTop: "10px" }}
                        >
                          <Select
                            name="franchise"
                            onChange={props.handleFranchise}
                            value={props.franchise || franchiseName}
                            // onChange={handleChange}
                            style={{
                              backgroundColor:
                                suspendedFranshise.leadSuspend == "1"
                                  ? "#ffd4d4"
                                  : suspendedFranshise.leadSuspend == "0"
                                  ? "#c6fac6"
                                  : "",
                            }}
                          >
                            <MenuItem value="">
                              <em>Select</em>
                            </MenuItem>
                            {franchiseList.length > 0 ? (
                              franchiseList.map((fran) =>
                                fran.leadSuspend == 0 ? (
                                  <MenuItem
                                    value={fran.companyID}
                                    key={fran.companyID}
                                    style={{ background: "#c6fac6" }}
                                  >
                                    {fran.businessType +
                                      " " +
                                      "-" +
                                      " " +
                                      fran.name}
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    value={fran.companyID}
                                    key={fran.companyID}
                                    style={{ background: "#ffd4d4" }}
                                  >
                                    {fran.businessType +
                                      " " +
                                      "-" +
                                      " " +
                                      fran.name}
                                  </MenuItem>
                                )
                              )
                            ) : (
                              <MenuItem value="">No Franchisee Found</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {franchiseList.length < 1 && (
                          <div className="text-danger">
                            Unfortunately there is no one currently servicing
                            your area. Sorry we are unable to assist you at this
                            time.
                          </div>
                        )}
                      </div>

                      {showOrgBox && (
                        <Fade
                          in={
                            commpanyType.length > 0 &&
                            commpanyType !== props.currentOrganization
                          }
                        >
                          <div style={{ position: "relative" }}>
                            <div className="yellow_triangle"></div>
                            <Card className="organization_info">
                              <div>
                                Unfortunately we don’t have{" "}
                                <span className="org_font">
                                  {props.currentOrganization}
                                </span>
                                , but have{" "}
                                <span className="org_font">{commpanyType}</span>
                                , would you like to proceed with our{" "}
                                <span className="org_font">{commpanyType}</span>{" "}
                                Franchisee?
                                <div className="mt-3">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                    onClick={props.handleEndCall}
                                  >
                                    No, End Call Now
                                  </Button>
                                  <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className="ml-3"
                                    onClick={handleCloseOrgBox}
                                  >
                                    Yes, Continue with Customer Details
                                  </Button>
                                </div>
                              </div>
                              <span>
                                <Close
                                  fontSize="small"
                                  onClick={() => setShowOrgBox(false)}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </Card>
                          </div>
                        </Fade>
                      )}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="mt-4">
                    <InputLabel style={{ marginBottom: "10px" }}>
                      Suburb
                    </InputLabel>
                    <Autocomplete
                      options={suburbStackList}
                      getOptionLabel={(option) => option.suburbName}
                      inputValue={inputValue}
                      value={suburbCurrent.length > 0 && suburbCurrent[0]}
                      onInputChange={handleInputChange}
                      onChange={handleSuburbSelect}
                      onKeyPress={handleSuburbPress}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText="Press Enter after you enter suburb to appear suburb list."
                          // InputProps={{
                          //   ...params.InputProps,
                          //   type: "search",
                          // }}
                        />
                      )}
                    />
                  </div>

                  {suburbCurrent.length > 0 && (
                    <div className="mt-4">
                      <InputLabel>Post Code</InputLabel>
                      <TextField
                        variant="outlined"
                        name="postCode"
                        value={props.postCode}
                        margin="normal"
                        fullWidth
                      />
                    </div>
                  )}

                  {suburbCurrent.length > 0 && (
                    <div className="mt-3">
                      <InputLabel>Franchise</InputLabel>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <Select
                          name="franchise"
                          onChange={props.handleFranchise}
                          value={props.franchise || franchiseName}
                          // onChange={handleChange}
                          style={{
                            backgroundColor:
                              suspendedFranshise.leadSuspend == "1"
                                ? "#ffd4d4"
                                : suspendedFranshise.leadSuspend == "0"
                                ? "#c6fac6"
                                : "",
                          }}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {franchiseList.length > 0 ? (
                            franchiseList.map((fran) =>
                              fran.leadSuspend == 0 ? (
                                <MenuItem
                                  value={fran.companyID}
                                  key={fran.companyID}
                                  style={{ background: "#c6fac6" }}
                                >
                                  {fran.businessType +
                                    " " +
                                    "-" +
                                    " " +
                                    fran.name}
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  value={fran.companyID}
                                  key={fran.companyID}
                                  style={{ background: "#ffd4d4" }}
                                >
                                  {fran.businessType +
                                    " " +
                                    "-" +
                                    " " +
                                    fran.name}
                                </MenuItem>
                              )
                            )
                          ) : (
                            <MenuItem value="">No Franchisee Found</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      {franchiseList.length < 1 && (
                        <div className="text-danger">
                          Unfortunately there is no one currently servicing your
                          area. Sorry we are unable to assist you at this time.
                        </div>
                      )}
                    </div>
                  )}
                  {showOrgBox && (
                    <Fade
                      in={
                        commpanyType.length > 0 &&
                        commpanyType !== props.currentOrganization
                      }
                    >
                      <div style={{ position: "relative" }}>
                        <div className="yellow_triangle"></div>
                        <Card className="organization_info">
                          <div>
                            Unfortunately we don’t have{" "}
                            <span className="org_font">
                              {props.currentOrganization}
                              {/* {commpanyType} */}
                            </span>
                            , but have{" "}
                            <span className="org_font">{commpanyType}</span>,
                            would you like to proceed with our{" "}
                            <span className="org_font">{commpanyType}</span>{" "}
                            Franchisee?
                            <div className="mt-3">
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={props.handleEndCall}
                              >
                                No, End Call Now
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="ml-3"
                                onClick={handleCloseOrgBox}
                              >
                                Yes, Continue with Customer Details
                              </Button>
                            </div>
                          </div>
                          <span>
                            <Close
                              fontSize="small"
                              onClick={() => setShowOrgBox(false)}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Card>
                      </div>
                    </Fade>
                  )}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <div className="mt-4">
                    <InputLabel style={{ marginBottom: "10px" }}>
                      Franchise Name
                    </InputLabel>
                    <Autocomplete
                      options={currentFranchiseList}
                      getOptionLabel={(option) =>
                        option.memberName +
                        " - " +
                        option.businessType +
                        (option.serviceLocation &&
                          " - " + option.serviceLocation)
                      }
                      inputValue={franchiseInput}
                      value={currentSelectedFranchise}
                      onInputChange={handleFranchiseInputChange}
                      onChange={handleFranchiseChange}
                      onKeyPress={handleFranchisePress}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          helperText="Press Enter after you enter franchisee name to appear franchisee list."
                          // InputProps={{
                          //   ...params.InputProps,
                          //   type: "search",
                          // }}
                        />
                      )}
                    />
                  </div>

                  {props.postCode && (
                    <div className="mt-4">
                      <InputLabel>Post Code</InputLabel>
                      <TextField
                        variant="outlined"
                        name="postCode"
                        value={props.postCode}
                        margin="normal"
                        fullWidth
                      />
                    </div>
                  )}

                  {franchiseSuburbsList && franchiseSuburbsList.length > 0 && (
                    <div className="mt-4">
                      <InputLabel>Suburb</InputLabel>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <Select
                          name="suburb"
                          // onChange={(e) => handleSuburb(e)}
                          value={newSuburbName || franchiseSuburb}
                          // onChange={props.handleSuburb}
                          onChange={handleFranchiseSuburb}
                        >
                          <MenuItem value="">
                            <em>Select</em>
                          </MenuItem>
                          {franchiseSuburbsList &&
                            franchiseSuburbsList.map((sub) =>
                              sub.count == "0" ? (
                                <MenuItem
                                  style={{ color: "red" }}
                                  key={sub.suburbID}
                                  value={sub.suburbName}
                                >
                                  <span style={{ color: "red" }}>
                                    {sub.suburbName +
                                      (sub.stateShortName &&
                                        "," + " " + sub.stateShortName)}
                                  </span>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  style={{ color: "green" }}
                                  key={sub.suburbID}
                                  value={sub.suburbName}
                                >
                                  <span style={{ color: "green" }}>
                                    {sub.suburbName +
                                      (sub.stateShortName &&
                                        "," + " " + sub.stateShortName)}
                                  </span>
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                      {franchiseSuburbsList.length < 1 && (
                        <div className="text-danger">
                          Unfortunately, No Suburb Found
                        </div>
                      )}
                    </div>
                  )}

                  {showOrgBox && (
                    <Fade
                      in={
                        commpanyType.length > 0 &&
                        commpanyType !== props.currentOrganization
                      }
                    >
                      <div style={{ position: "relative" }}>
                        <div className="yellow_triangle"></div>
                        <Card className="organization_info">
                          {props.messageOperator ? (
                            <div>
                              FYI, selected operator operates under{" "}
                              <span className="org_font">{commpanyType}</span>,
                              <div className="mt-3">
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  onClick={props.handleEndCall}
                                >
                                  No, End Call Now
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className="ml-3"
                                  onClick={handleCloseOrgBox}
                                >
                                  Yes, Continue with Customer Details
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              Unfortunately we don’t have{" "}
                              <span className="org_font">
                                {props.currentOrganization}
                              </span>
                              , but have{" "}
                              <span className="org_font">{commpanyType}</span>,
                              would you like to proceed with our{" "}
                              <span className="org_font">{commpanyType}</span>{" "}
                              Franchisee?
                              <div className="mt-3">
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  onClick={props.handleEndCall}
                                >
                                  No, End Call Now
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className="ml-3"
                                  onClick={handleCloseOrgBox}
                                >
                                  Yes, Continue with Customer Details
                                </Button>
                              </div>
                            </div>
                          )}
                          <span>
                            <Close
                              fontSize="small"
                              onClick={() => setShowOrgBox(false)}
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </Card>
                      </div>
                    </Fade>
                  )}
                </TabPanel>
              </Box>

              <div className="mt-3">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={props.handleBack}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.handleContinue}
                  className="ml-3"
                  disabled={
                    (value === 0 &&
                      (franchiseList.length < 1 ||
                        (!!suburbList &&
                          suburbList[0] === "No Valid Suburb found on system" &&
                          !props.saveCall))) ||
                    (value === 1 &&
                      (suburbCurrent.length < 1 || franchiseList.length < 1)) ||
                    (value === 2 && currentFranchiseList.length < 1)
                  }
                >
                  {/* {props.serviceRequired && "Continue with call"}
                {props.cancelBooking && "Continue Cancel Booking"}
                {props.franchiseEnquiry && "Continue with enquiry"}
                {props.advertisingEnquiry && "Continue with enquiry"}
                {props.complaint && "Continue with complaint"}
                {props.messageOperator && "Continue Message for Operator"}
                {props.declined && "Continue to decline"} */}
                  Continue with Call
                </Button>

                {props.saveCall && !showEndCall && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleSaveCall}
                    className="ml-3"
                    disabled={
                      (value === 0 &&
                        !!suburbList &&
                        suburbList[0] === "No Valid Suburb found on system" &&
                        !props.saveCall) ||
                      (value === 1 &&
                        (suburbCurrent.length < 1 ||
                          franchiseList.length < 1)) ||
                      (value === 2 && currentFranchiseList.length < 1)
                    }
                  >
                    Save Call
                  </Button>
                )}

                {suburbList &&
                  suburbList.length > 0 &&
                  franchiseList.length < 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={props.handleEndCall}
                      className="ml-3"
                    >
                      End Call
                    </Button>
                  )}

                {showEndCall && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleSaveCall}
                    className="float-right"
                  >
                    Save Call
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default SelectPostCode;
