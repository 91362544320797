import React, { useState, useEffect } from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import ChartCard from "./ChartCard";
import { increamentData } from "../dashboard/routes/Listing/mdata";
import BasicTable from "./BasicTable";
import SearchResults from "./SearchResults";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
//-------For Tab component---------------//
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
//--------------------------------------//
// import Button from "@material-ui/core/Button";
// import ContainerHeader from "components/ContainerHeader/index";
// import IntlMessages from "util/IntlMessages";

const qs = require("qs");
const loginData = JSON.parse(window.localStorage.getItem("login"));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    height: 124,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const DashboardOne = ({ match }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  //-------The state "apiQuery" is used for selecting data from local storage and assisting in API calls-----//
  const [apiQuery] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    type: "recent",
    appID: "1",
  });

  const [filterQuery, setFilterQuery] = useState({
    userID: loginData && loginData.uid,
    userCode: loginData && loginData.aCode,
    search: "",
  });

  const [callRange, setCallRange] = useState({
    numbers: {},
    recentCalls: [],
  });
  const [searchMode, setSearchMode] = useState(false); //---A boolean that enables to show the "Search results Tab---//"
  const [filteredData, setFilteredData] = useState(); //---An empty state that stores the values from an API that provides filtered data--//
  const [filterCount, setFilterCount] = useState();

  useEffect(() => {
    getCallRange();
  }, []);

  const getCallRange = () => {
    //-------This function fetches the data for "Recent Calls" & "Average Calls statistics"---//
    setFilteredData("");
    setSearchMode(false);
    setFilterQuery({ ...filterQuery, search: "" });

    httpClient
      .post(
        // "getCallLog.php",
        "getCallLog2.php",
        qs.stringify({
          action: "call_log",
          source: apiQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Data not available right now");
        } else {
          const formattedRecentCalls =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                ...item,
                messageType:
                  item.endCall === "1" ? "end_call" : item.messageType,
                callScenario: item.endCall === "1" ? item.messageType : "",
              };
            });
          setTabValue(0);
          setCallRange({
            recentCalls: formattedRecentCalls,
            numbers: res.data.numbers,
          });
        }
      });
  };

  const getSearchResults = () => {
    //-------This function filters the RECENT CALLS on the basis of query entered in the search box"----//
    httpClient
      .post(
        // "searchCallLog.php",
        "getCallLog2.php",
        qs.stringify({
          // action: "search_log",
          action: "call_log",
          source: filterQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Data not available right now");
        } else {
          setTabValue(1);
          setFilteredData(res.data.data);
          setFilterCount(res.data.count);
        }
      });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchQuery = (e) => {
    setFilterQuery({ ...filterQuery, search: e.target.value });
  };

  const filterRecentCalls = () => {
    //----This function enables to manipulate the boolean values and invokes the function that fetches filtered data---//
    if (filterQuery.search === "") {
      setSearchMode(false);
      setFilteredData("");
      setTabValue(0);
    } else {
      setSearchMode(true);
      getSearchResults();
    }
  };

  //******************* Tabs Function & state *********************//
  const [tabValue, setTabValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  const handleTab = (e, tabIndex) => {
    setTabValue(tabIndex);
  };

  //*****************************************************************//

  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      <div className="row">
        <div
          className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
          style={{ marginTop: "2%", marginLeft: "2%" }}
        >
          <ChartCard
            prize={callRange && callRange.numbers && callRange.numbers.tcalls} //----showing "Total calls" value from state ----//
            icon="Number of calls"
            children={
              <ResponsiveContainer
                className="card-img-bottom overflow-hidden"
                width="100%"
                height={75}
              >
                <AreaChart
                  data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                      <stop offset="  5%" stopColor="#aa3c6d" stopOpacity={1} />
                      <stop offset="95%" stopColor="#ff9800" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <Area
                    dataKey="pv"
                    type="monotone"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#4D95F3"
                    fill="url(#color4)"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
            desc="Number of calls"
          />
        </div>
        <div
          className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
          style={{ marginTop: "2%", marginLeft: "2%" }}
        >
          <ChartCard
            prize={callRange && callRange.numbers && callRange.numbers.acalls} //----showing "Average calls" value from state----//
            icon="bitcoin"
            children={
              <ResponsiveContainer
                className="card-img-bottom overflow-hidden"
                width="100%"
                height={75}
              >
                <AreaChart
                  data={increamentData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                      <stop offset="5%" stopColor="#3f51b5" stopOpacity={1} />
                      <stop offset="95%" stopColor="#1fb6fc" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <Area
                    dataKey="pv"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#4D95F3"
                    fill="url(#color3)"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
            styleName="up"
            desc="Average calls"
          />
        </div>

        <div
          className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
          style={{ marginTop: "2%", marginBottom: "1%" }}
        >
          <Paper className={classes.root}>
            <span style={{ padding: 10 }}>
              <MenuIcon />
            </span>
            <InputBase
              className={classes.input}
              placeholder="Search Call Log"
              inputProps={{ "aria-label": "Search Call Log" }}
              name="search"
              value={filterQuery.search}
              onChange={(e) => handleSearchQuery(e)}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={filterRecentCalls}
            >
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
          </Paper>
        </div>
      </div>

      <div className="row">
        <div className={classes.rootTab}>
          <AppBar position="static" style={{ width: "90%", marginLeft: "5%" }}>
            <Tabs value={tabValue} onChange={handleTab}>
              <Tab label="Recent Calls" />

              {filteredData !== "" ? (
                <Tab
                  label={
                    <span style={{ fontColor: "white" }}>
                      Search Results
                      {/* {filterCount === "" ? "empty" : filterCount}) */}
                    </span>
                  }
                />
              ) : null}
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <BasicTable
              openModal={openModal}
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              searchMode={searchMode}
              recentCalls={callRange && callRange.recentCalls}
            />
          </TabPanel>

          {searchMode ? (
            <TabPanel value={tabValue} index={1}>
              <SearchResults
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                filteredData={filteredData}
              />
            </TabPanel>
          ) : null}
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default DashboardOne;
