import { Button, InputLabel, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import ThankYouReference from "../../../components/ThankYou";

function SaveCall(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (props.saveCallerPressed.firstName || props.saveCallerPressed.lastName) {
      setStage(0);
    } else if (
      props.saveCallerPressed.phoneNumber1 ||
      props.saveCallerPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.saveCallerPressed.address) {
      setStage(2);
    } else if (
      props.saveCallerPressed.suburb ||
      props.saveCallerPressed.postCode
    ) {
      setStage(3);
    } else {
    }
  }, [props]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleSave = () => {
    props.handleEndCall();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "submit") {
        handleSave();
      } else {
        nextfield = document.querySelector(`input[name=${next}]`);
        nextfield.focus();
      }
    }
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {/* {!props.saveCaller.customerName && (
            <TopInfoBox text="May I have your name please ?" />
          )}
          {props.saveCaller.customerName &&
            !props.saveCaller.phoneNumber1 &&
            !props.saveCaller.phoneNumber2 && (
              <TopInfoBox text="And what is the best number to contact you on ? " />
            )}

          {props.saveCaller.customerName &&
            (props.saveCaller.phoneNumber1 || props.saveCaller.phoneNumber2) &&
            !props.saveCaller.address && (
              <TopInfoBox text="May I take your address please ?" />
            )}

          {props.saveCaller.customerName &&
            (props.saveCaller.phoneNumber1 || props.saveCaller.phoneNumber2) &&
            // !props.saveCaller.message &&
            !messageSubmit && (
              <TopInfoBox text="Can you also please provide your suburb and postcode ?" />
            )}

          {props.saveCaller.customerName &&
            (props.saveCaller.phoneNumber1 || props.saveCaller.phoneNumber2) &&
            props.saveCaller.address && messageSubmit &&
            messageSubmit && <TopInfoBox text="Thank you for your call" />} */}
          {stage === 0 && !messageSubmit && (
            <TopInfoBox text="May I have your name please ?" />
          )}
          {stage === 1 && !messageSubmit && (
            <TopInfoBox text="And what is the best number to contact you on ? " />
          )}

          {stage === 2 && !messageSubmit && (
            <TopInfoBox text="May I take your address please ?" />
          )}

          {stage === 3 && !messageSubmit && (
            <TopInfoBox text="Can you also please provide your suburb and postcode ?" />
          )}

          {messageSubmit && <TopInfoBox text="Thank you for your call" />}

          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="mb-2">FirstName</InputLabel>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    value={props.saveCaller.firstName}
                    onChange={props.handleChangeSave}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                    helperText={"Please enter the First Name"}
                  />
                </div>

                <div className="col-md-6">
                  <InputLabel className="mb-2">LastName</InputLabel>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    value={props.saveCaller.lastName}
                    onChange={props.handleChangeSave}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                    helperText={"Please enter the Last Name"}
                  />
                </div>

                {/* {(props.saveCaller.firstName || props.saveCaller.lastName) && ( */}
                <div className="col-md-6 mt-4">
                  <InputLabel className="mb-2">Phone Number 1</InputLabel>
                  <TextField
                    variant="outlined"
                    name="phoneNumber1"
                    value={props.saveCaller.phoneNumber1}
                    onChange={props.handleChangeSave}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                    helperText={"Please enter the Phone Number"}
                  />
                </div>
                {/* )} */}

                {/* {(props.saveCaller.firstName || props.saveCaller.lastName) && ( */}
                <div className="col-md-6 mt-4">
                  <InputLabel className="mb-2">Phone Number 2</InputLabel>
                  <TextField
                    variant="outlined"
                    name="phoneNumber2"
                    value={props.saveCaller.phoneNumber2}
                    onChange={props.handleChangeSave}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "address")}
                    helperText={"Please enter the Phone Number"}
                  />
                </div>
                {/* )} */}

                {/* {(props.saveCaller.firstName || props.saveCaller.lastName) &&
                (props.saveCaller.phoneNumber1 ||
                  props.saveCaller.phoneNumber2) && ( */}
                <div className="col-md-12 mt-4">
                  <InputLabel className="mb-2">Address</InputLabel>
                  <TextField
                    variant="outlined"
                    name="address"
                    value={props.saveCaller.address}
                    onChange={props.handleChangeSave}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "suburb")}
                    helperText={"Please enter the Address"}
                  />
                </div>
                <div className="col-md-6 mt-4">
                  <InputLabel className="mb-2">Suburb</InputLabel>
                  <TextField
                    variant="outlined"
                    name="suburb"
                    onChange={props.handleChangeSave}
                    value={props.saveCaller.suburb}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "postCode")}
                    helperText={"Please enter the Suburb"}
                  />
                </div>

                <div className="col-md-6 mt-4">
                  <InputLabel className="mb-2">Post Code</InputLabel>
                  <TextField
                    variant="outlined"
                    name="postCode"
                    onChange={props.handleChangeSave}
                    value={props.saveCaller.postCode}
                    className={classes.textField}
                    onFocus={props.handleChangeSavePressed}
                    onKeyUp={(e) => handleKeyEnter(e, "submit")}
                    helperText={"Please enter the Post code"}
                  />
                </div>
                {/* )} */}

                <div className=" col-md-12 mt-3">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.handleBack}
                  >
                    Back
                  </Button>
                  {/* {(props.saveCaller.firstName ||
                  props.saveCaller.lastName) &&
                  (props.saveCaller.phoneNumber1 ||
                    props.saveCaller.phoneNumber2) && ( */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    className="ml-3"
                  >
                    End Call
                  </Button>
                  {/* )} */}
                  {props.showResetButton && (
                    <Button
                      variant="contained"
                      className="ml-3"
                      color="default"
                      onClick={props.handleReset}
                    >
                      Reset
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default SaveCall;
