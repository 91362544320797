import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";
import ThankYouReference from "../../../components/ThankYou";

function ContinueComplaint(props) {
  const classes = useStyles();
  const [messageSubmit, setMessageSubmit] = useState(false);
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (props.enquiryPressed.firstName || props.enquiryPressed.lastName) {
      setStage(0);
    } else if (
      props.enquiryPressed.phoneNumber1 ||
      props.enquiryPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.enquiryPressed.address) {
      setStage(2);
    } else if (props.enquiryPressed.message) {
      setStage(3);
    } else {
    }
  }, [props]);

  useEffect(() => {
    props.showResetButton && setMessageSubmit(true);
  }, [props.showResetButton]);

  const handleSubmit = () => {
    const isValid = handlePhoneNumberValidation(
      props.enquiryDetails.phoneNumber1,
      props.enquiryDetails.phoneNumber2
    );

    if (!isValid) {
      return;
    }

    props.handleSubmitComplaint();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      var nextfield;
      if (next === "message") {
        nextfield = document.querySelector(`textarea[name=${next}]`);
      } else {
        nextfield = document.querySelector(`input[name=${next}]`);
      }
      nextfield.focus();
    }
  };

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
            <TopInfoBox
              text={`Unfortunately ${props.suspendedFranchisee.name ||
                props.suspendedFranchisee.memberName} is on leave from ${moment(
                props.suspendedFranchisee.leadSuspendStartDate
              ).format("dddd, Do MMMM YYYY")} to ${moment(
                props.suspendedFranchisee.leadSuspendEndDate
              ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                .suspendedFranchisee.leadSuspendReasonID ||
                props.suspendedFranchisee.leadSuspendReason}`}
            />
          )}

          {stage === 0 && !messageSubmit && (
            <TopInfoBox text="May I have your name please ?" />
          )}
          {stage === 1 && !messageSubmit && (
            <TopInfoBox text="And what is the best number to contact you on ? " />
          )}

          {stage === 2 && !messageSubmit && (
            <TopInfoBox text="May I take your address please ?" />
          )}

          {stage === 3 && !messageSubmit && (
            <TopInfoBox
              text={"Can you please provide me with details of the complaint?"}
            />
          )}

          {messageSubmit && (
            <TopInfoBox
              text={
                "Thank you for calling, I will pass on your message straight away and have your call returned as soon as possible."
              }
            />
          )}
          {!props.showResetButton ? (
            <div className={classes.selectBox}>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabel className="mb-2">First Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="firstName"
                      value={props.enquiryDetails.firstName}
                      onChange={props.handleEnquiryChange}
                      className={classes.textField}
                      onFocus={props.handleEnquiryPress}
                      onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                      helperText={"Press enter the Customer's First Name"}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputLabel className="mb-2">last Name</InputLabel>
                    <TextField
                      variant="outlined"
                      name="lastName"
                      value={props.enquiryDetails.lastName}
                      onChange={props.handleEnquiryChange}
                      className={classes.textField}
                      onFocus={props.handleEnquiryPress}
                      onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                      helperText={"Press enter the Customer's Last Name"}
                    />
                  </div>
                  {(props.enquiryDetails.firstName ||
                    props.enquiryDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 1</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber1"
                        type="number"
                        value={props.enquiryDetails.phoneNumber1}
                        onChange={props.handleEnquiryChange}
                        className={classes.textField}
                        onFocus={props.handleEnquiryPress}
                        onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                        helperText={"Press enter the Phone Number"}
                      />
                    </div>
                  )}
                  {(props.enquiryDetails.firstName ||
                    props.enquiryDetails.lastName) && (
                    <div className="col-md-6 mt-4">
                      <InputLabel className="mb-2">Phone Number 2</InputLabel>
                      <TextField
                        variant="outlined"
                        name="phoneNumber2"
                        type="number"
                        value={props.enquiryDetails.phoneNumber2}
                        onChange={props.handleEnquiryChange}
                        className={classes.textField}
                        onFocus={props.handleEnquiryPress}
                        onKeyUp={(e) => handleKeyEnter(e, "address")}
                        helperText={"Press enter the Phone Number"}
                      />
                    </div>
                  )}

                  {(props.enquiryDetails.firstName ||
                    props.enquiryDetails.lastName) &&
                    (props.enquiryDetails.phoneNumber1 ||
                      props.enquiryDetails.phoneNumber2) && (
                      <>
                        <div className="col-md-12 mt-4">
                          <InputLabel className="mb-2">Address</InputLabel>
                          <TextField
                            variant="outlined"
                            name="address"
                            value={props.enquiryDetails.address}
                            onChange={props.handleEnquiryChange}
                            className={classes.textField}
                            onFocus={props.handleEnquiryPress}
                            onKeyUp={(e) => handleKeyEnter(e, "suburb")}
                            helperText={"Press enter the Address"}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">Suburb</InputLabel>
                          <TextField
                            variant="outlined"
                            name="suburb"
                            value={props.suburb}
                            className={classes.textField}
                            // disabled={true}
                            onChange={props.handleChange}
                            onKeyUp={(e) => handleKeyEnter(e, "state")}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">State</InputLabel>
                          <TextField
                            variant="outlined"
                            name="state"
                            value={props.state}
                            className={classes.textField}
                            // disabled={true}
                            onChange={props.handleChange}
                            onKeyUp={(e) => handleKeyEnter(e, "postCode")}
                          />
                        </div>
                        <div className="col-md-4 mt-4">
                          <InputLabel className="mb-2">Post Code</InputLabel>
                          <TextField
                            variant="outlined"
                            name="postCode"
                            value={props.postCode}
                            className={classes.textField}
                            // disabled={true}
                            onChange={props.handleChange}
                            onKeyUp={(e) => handleKeyEnter(e, "message")}
                          />
                        </div>
                      </>
                    )}

                  {(props.enquiryDetails.firstName ||
                    props.enquiryDetails.lastName) &&
                    (props.enquiryDetails.phoneNumber1 ||
                      props.enquiryDetails.phoneNumber2) &&
                    props.enquiryDetails.address && (
                      <div className="col-md-12 mt-4">
                        <InputLabel className="mb-2">Complaint</InputLabel>
                        <TextField
                          multiline
                          rows={4}
                          variant="outlined"
                          name="message"
                          value={props.enquiryDetails.message}
                          onChange={props.handleEnquiryChange}
                          className={classes.textField}
                          onFocus={props.handleEnquiryPress}
                          //   helperText={"Press enter the Message"}
                        />
                      </div>
                    )}

                  <div className="mt-3">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={props.handleBack}
                    >
                      Back
                    </Button>
                    {(props.enquiryDetails.firstName ||
                      props.enquiryDetails.lastName) &&
                      (props.enquiryDetails.phoneNumber1 ||
                        props.enquiryDetails.phoneNumber2) &&
                      props.enquiryDetails.address &&
                      props.enquiryDetails.message && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          className="ml-3"
                        >
                          Submit
                        </Button>
                      )}
                    {props.showResetButton && (
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="default"
                        onClick={props.handleReset}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <ThankYouReference
              referenceNumber={props.callRefNumber}
              handleReset={props.handleReset}
            />
          )}
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ContinueComplaint;
