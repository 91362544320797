import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import useStyles from "./style";
import TopInfoBox from "./topInfoBox";
import { handlePhoneNumberValidation } from "../../../appUtility/phoneNumberUtils";

function ContinueCancelBooking(props) {
  const classes = useStyles();
  const [cancelBookingSubmit, setCancelBookingSubmit] = useState(false);
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (props.callerPressed.firstName || props.callerPressed.lastName) {
      setStage(0);
    } else if (
      props.callerPressed.phoneNumber1 ||
      props.callerPressed.phoneNumber2
    ) {
      setStage(1);
    } else if (props.callerPressed.address) {
      setStage(2);
    } else if (props.callerPressed.arrangeCall) {
      setStage(3);
    } else {
      setStage(4);
    }
  }, [props]);

  const handleSubmit = () => {
    const isValid = handlePhoneNumberValidation(
      props.callerDetails.phoneNumber1,
      props.callerDetails.phoneNumber2
    );

    if (!isValid) {
      return;
    }

    setCancelBookingSubmit(true);
    // props.handleSubmitCancelBooking();
    props.handleContinue();
  };

  const handleKeyEnter = (e, next) => {
    if (e.key === "Enter") {
      if (next === "enter") {
        handleSubmit();
      } else {
        const nextfield = document.querySelector(`input[name=${next}]`);
        nextfield.focus();
      }
    }
  };

  const showMessageField =
    (props.callerDetails.firstName || props.callerDetails.lastName) &&
    (props.callerDetails.phoneNumber1 || props.callerDetails.phoneNumber2) &&
    props.callerDetails.address;

  return (
    <ResponsiveContainer>
      <div className="row">
        <div className="col-md-12">
          {stage === 0 && props.suspendedFranchisee.leadSuspend == "1" && (
            <TopInfoBox
              text={`Unfortunately ${props.suspendedFranchisee.name ||
                props.suspendedFranchisee.memberName} is on leave from ${moment(
                props.suspendedFranchisee.leadSuspendStartDate
              ).format("dddd, Do MMMM YYYY")} to ${moment(
                props.suspendedFranchisee.leadSuspendEndDate
              ).format("dddd, Do MMMM YYYY")} and the reason is ${props
                .suspendedFranchisee.leadSuspendReason ||
                props.suspendedFranchisee.leadSuspendReason}`}
            />
          )}
          {stage === 0 && <TopInfoBox text="May I have your name please ?" />}
          {stage === 1 && (
            <TopInfoBox text="And what is the best number to contact you on ? " />
          )}

          {stage === 2 && (
            <TopInfoBox text="May I take your address please ?" />
          )}

          {stage === 3 && (
            <TopInfoBox
              text={`May I arrange ${props.operatorName} for to call you to reschedule ?`}
            />
          )}
          {stage === 4 && (
            <TopInfoBox text="May I take brief details of your message" />
          )}

          {cancelBookingSubmit && (
            <TopInfoBox text="Thank you for your call, I will pass on your message straight away and have your call returned as soon as possible." />
          )}

          <div className={classes.selectBox}>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <InputLabel className="mb-2">FirstName</InputLabel>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    value={props.callerDetails.firstName}
                    onChange={props.handleChange}
                    className={classes.textField}
                    onFocus={props.handleCallerFocus}
                    onKeyUp={(e) => handleKeyEnter(e, "lastName")}
                    helperText={"Press enter the First Name"}
                  />
                </div>
                <div className="col-md-6">
                  <InputLabel className="mb-2">LastName</InputLabel>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    value={props.callerDetails.lastName}
                    onChange={props.handleChange}
                    className={classes.textField}
                    onFocus={props.handleCallerFocus}
                    onKeyUp={(e) => handleKeyEnter(e, "phoneNumber1")}
                    helperText={"Press enter the Last Name"}
                  />
                </div>
                {(props.callerDetails.firstName ||
                  props.callerDetails.lastName) && (
                  <div className="col-md-6 mt-4">
                    <InputLabel className="mb-2">Phone Number 1</InputLabel>
                    <TextField
                      variant="outlined"
                      name="phoneNumber1"
                      type="number"
                      value={props.callerDetails.phoneNumber1}
                      onChange={props.handleChange}
                      className={classes.textField}
                      onFocus={props.handleCallerFocus}
                      onKeyUp={(e) => handleKeyEnter(e, "phoneNumber2")}
                      helperText={"Press enter the Phone Number"}
                    />
                  </div>
                )}
                {(props.callerDetails.firstName ||
                  props.callerDetails.lastName) && (
                  <div className="col-md-6 mt-4">
                    <InputLabel className="mb-2">Phone Number 2</InputLabel>
                    <TextField
                      variant="outlined"
                      name="phoneNumber2"
                      type="number"
                      value={props.callerDetails.phoneNumber2}
                      onChange={props.handleChange}
                      className={classes.textField}
                      onFocus={props.handleCallerFocus}
                      onKeyUp={(e) => handleKeyEnter(e, "address")}
                      helperText={"Press enter the Phone Number"}
                    />
                  </div>
                )}
                {(props.callerDetails.firstName ||
                  props.callerDetails.lastName) &&
                  (props.callerDetails.phoneNumber1 ||
                    props.callerDetails.phoneNumber2) && (
                    <>
                      <div className="col-md-12 mt-4">
                        <InputLabel className="mb-2">Address</InputLabel>
                        <TextField
                          variant="outlined"
                          name="address"
                          value={props.callerDetails.address}
                          onChange={props.handleChange}
                          className={classes.textField}
                          onFocus={props.handleCallerFocus}
                          onKeyUp={(e) => handleKeyEnter(e, "suburb")}
                          helperText={"Press enter the Address"}
                        />
                      </div>
                      <div className="col-md-4 mt-4">
                        <InputLabel className="mb-2">Suburb</InputLabel>
                        <TextField
                          variant="outlined"
                          name="suburb"
                          value={props.suburb}
                          className={classes.textField}
                          // disabled={true}
                          onChange={props.handleChange}
                          onKeyUp={(e) => handleKeyEnter(e, "state")}
                        />
                      </div>
                      <div className="col-md-4 mt-4">
                        <InputLabel className="mb-2">State</InputLabel>
                        <TextField
                          variant="outlined"
                          name="state"
                          value={props.state}
                          className={classes.textField}
                          onChange={props.handleChange}
                          onKeyUp={(e) => handleKeyEnter(e, "postCode")}
                          // disabled={true}
                        />
                      </div>
                      <div className="col-md-4 mt-4">
                        <InputLabel className="mb-2">Post Code</InputLabel>
                        <TextField
                          variant="outlined"
                          name="postCode"
                          value={props.postCode}
                          className={classes.textField}
                          // disabled={true}
                          onChange={props.handleChange}
                          onKeyUp={(e) => handleKeyEnter(e, "enter")}
                        />
                      </div>
                    </>
                  )}
                {/* {(props.callerDetails.firstName || props.callerDetails.lastName) &&
                  (props.callerDetails.phoneNumber1 || props.callerDetails.phoneNumber2) &&
                  // props.callerDetails.address &&
                  // !props.callerDetails.arrangeCall && 
                  (
                    <div className="col-md-12 mt-4">
                      <FormControl>
                        <FormLabel className="mb-2">
                          <h3 style={{ fontWeight: "700" }}>Re-Schedule</h3>
                        </FormLabel>

                        <RadioGroup
                          name="arrangeCall"
                          value={props.arrangeCall}
                          onChange={props.handleChange}
                        >
                          <FormControlLabel
                            className={classes.label}
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            className={classes.label}
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )} */}
                {showMessageField && (
                  <div className="col-md-12 mt-4">
                    <InputLabel className="mb-2">Message</InputLabel>
                    <TextField
                      multiline
                      rows={4}
                      variant="outlined"
                      name="message"
                      value={props.callerDetails.message}
                      className={classes.textField}
                      onChange={props.handleChange}
                      onFocus={props.handleCallerFocus}
                    />
                  </div>
                )}
                <div className="mt-3">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={props.handleBack}
                  >
                    Back
                  </Button>
                  {(props.callerDetails.firstName ||
                    props.callerDetails.lastName) &&
                    (props.callerDetails.phoneNumber1 ||
                      props.callerDetails.phoneNumber2) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className="ml-3"
                      >
                        Next
                      </Button>
                    )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
}

export default ContinueCancelBooking;
