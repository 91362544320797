import React from "react";
import { Button } from "@material-ui/core";

const ThankYouReference = ({ referenceNumber, handleReset }) => {
  return (
    <div className="centered-container">
      <p className="reference-text">
        Call Reference No: <strong>{referenceNumber}</strong>
      </p>
      <Button color="secondary" variant="contained" onClick={handleReset}>
        Start from beginning
      </Button>
    </div>
  );
};

export default ThankYouReference;
